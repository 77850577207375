import React, { useState, createContext, useEffect } from "react";

const PropertyContext = createContext();

const PropertyContextProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({});
  const [meta, setMeta] = useState({});

  const sendToListhub = (properties) => {
    const listhubProperties = properties.reduce((arr, prop) => {
      if (prop.listhub_key) {
        arr.push({ lkey: prop.listhub_key });
      }

      return arr;
    }, []);

    lh("submit", "SEARCH_DISPLAY", listhubProperties);
  };

  useEffect(() => {
    getProperties();
  }, [filters]);

  useEffect(() => {
    sendToListhub(properties);
  }, [properties]);

  const buildUrl = () => {
    let url = `/map/properties?`;
    for (const key in filters) {
      const value = filters[key];
      if (Array.isArray(value)) {
        value.forEach((value) => {
          url += `&${key}[]=${value}`;
        });
      } else {
        url += `&${key}=${value}`;
      }
    }

    return url;
  };

  const getProperties = async () => {
    if (filters.zoom < 10) return;

    try {
      const response = await fetch(buildUrl(), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();

      setProperties(json.properties);
      setMeta(json.meta_data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <PropertyContext.Provider
      value={{ properties, setProperties, setFilters, filters, meta }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export { PropertyContextProvider, PropertyContext };
