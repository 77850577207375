import React, { useCallback, useEffect, useState, useRef, useMemo, useContext, createContext } from 'react'
import debounce from '../custom/debounce'
import useOnClickOutside from '../hooks/onClickOutside'
import { PropertyContext } from '../search/context/property-context'
import retrievePlaces from '../hooks/retrievePlace'
import Suggestion from './components/suggestion'

interface Props {
  label?: string
  redirect?: boolean
  place?: string | null
  baseUrl?: string 
  onPlaceSelected?: ({lat, lng , city, state}: {lat: string, lng: string, city: string, state: string}) => void
  updateFilters?: boolean
  redirectParams?: string[]
}

const PlaceSearch: React.FunctionComponent<Props> = ({ onPlaceSelected, label, redirect=true, place, baseUrl, updateFilters=false, redirectParams}) => {
  const placeHolderContext = createContext({
    setFilters: () => {},
    filters: {}
  })

  const formattedRedirectParams = useMemo(() => {
    if(redirectParams) {
      return redirectParams.join('&');
    } else {
      return ''
    }
  },[redirectParams])
  const [search, setSearch] = useState(place || '')
  const [places, setPlaces] = useState([])
  const { filters, setFilters } = useContext(updateFilters ? PropertyContext : placeHolderContext)

  const listRef = useRef(null)
  const outsideClickCallback = useCallback(() => {
    setPlaces([])
  }, [])
  const domain = useMemo(() =>{
    if (baseUrl) {
      return baseUrl
    } 

    return ''
  }, [baseUrl])

  useOnClickOutside(listRef, outsideClickCallback)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if(params.has('place')) {
      const place = params.get('place')!
      setSearch(place)
    } 
  },[])

  useEffect(() => {
    if(search && document.activeElement?.id === 'place-search') {
      placesCallBack(search);
    } else { 
      setPlaces([]);
    }
  }, [search])

  
  
  const getPlaces = async (searchTerm) => {
    const url = new URL(window.location.href.toLowerCase());
    const lat = url.searchParams.get("lat");
    const lng = url.searchParams.get("lng");
    let requestUrl = `${domain}/place_suggestions?query=${searchTerm}`
    if (lat && lng) {
      requestUrl += `&lat=${lat}&lng=${lng}`
    }
    
    try {
      const response = await fetch(requestUrl)
      const results = await response.json()
      setPlaces(results.suggestions)
    } catch(e) {
      // eventually a toast message
    }
  }
  const placesCallBack = useCallback(debounce(getPlaces, 500), [])

  const resetSearch = (e)  => {
   setSearch('')
   const newFilters = {...filters }
   delete newFilters.city
   delete newFilters.state

   const url = new URL(location.href);
   url.searchParams.delete('city')
   url.searchParams.delete('state')
   window.history.replaceState({}, '', url.href)  
  
   setFilters(newFilters)
  }

  const placeSelectedCallback = async(mapboxId) => {
    const place = await retrievePlaces(mapboxId)
    setZoom(place.featureType)
    setPlaces([])
    const search = place.city === null ?  place.state :  `${place.city}, ${place.state}`
    setSearch(search)
    logSearch(JSON.stringify(filters), name)
    if(redirect) {
      handleRedirect(place)
    } else {
      onPlaceSelected(place)
    }
  }

  const setZoom = (featureType: string) => {
    const newFilters = {...filters }

    switch (featureType) {
      case 'address':
      case 'street':
        setFilters({ ...newFilters, zoom: 14 })
        break;
      case 'neighborhood':   
      setFilters({ ...newFilters, zoom: 12 })
      default:
        setFilters({ ...newFilters, zoom: 10 })
        break;
    }
  }

  const handleRedirect = ({lat, lng, state, city}) => {
    const url = `${domain}/search?lat=${lat}&lng=${lng}&state=${state}&city=${city}&${formattedRedirectParams}&zoom=${filters.zoom}`
    window.location.href = url;
  }


  const logSearch = async (query, name) => {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content
    const csrfParam = document.querySelector('meta[name=csrf-param]').content
    const body = new FormData();
    body.append(csrfParam, csrfToken)
    body.append('query', query)
    body.append('name', name)

    try {
      const result = await fetch(`/log_search`, {
        method: 'POST',
        body
      })
    } catch(e) {
      alert('Something went wrong, please try again.')
    }
  }

  const renderPlaces = () => places.map((place)=> {
    return <Suggestion key={place.mapbox_id} suggestion={place} onSelect={placeSelectedCallback} />
  })


  return (
    <div className="input-with-placeholder relative mobile:w-full group w-full mt-2"> 
      <div className="relative">
        <input id='place-search' type='text' className='peer-input peer border-slate-500 mobile:w-full' value={search} onChange={(event) => { setSearch(event.target.value); }} />
        { label && 
          <label htmlFor='place-search' className="peer-label text-neutral-600">{label}</label>	
        }
        { updateFilters && <button type="button" className="absolute right-6 top-4 text-lg" onClick={resetSearch}>&#x2715;</button> } 
     </div>
      <div ref={listRef} className='mt-1 absolute z-[9999] safari-zindex-fix'>
        <ul className={`border-solid bg-white border-cyan border-2 rounded-lg ${places.length ? 'visible' : 'hidden'}`}>
          {renderPlaces()}
        </ul>
      </div>
    </div>
  )
}

export default PlaceSearch;
