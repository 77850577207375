const formatData = (result) => {
  const [lng, lat] = result.geometry.coordinates;
  let city = result.properties.context?.place?.name || result.properties.name;
  let state;

  if (result.properties.feature_type == "region") {
    state = result.properties.name;
    city = null;
  } else {
    state = result.properties.context?.region?.name;
  }

  return {
    lat,
    lng,
    city,
    state,
    featureType: result.properties.feature_type
  };
};

const retrievePlaces = async (mapboxId) => {
  const result = await fetch(`/place_suggestions/${mapboxId}`);
  const json = await result.json();

  return formatData(json);
};

export default retrievePlaces;
